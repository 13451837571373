import * as React from "react"
import { SEO } from "../../components/seo"
import Layout from '../../components/vi/layout';
// import { StaticImage } from "gatsby-plugin-image"
import ScrollAnimation from 'react-animate-on-scroll';
// import { Link } from "gatsby";
import Form from '../../components/vi/form-volunteer'

const HowToHelp = (props) => {
  return (
    <Layout pathname={props.location.pathname}>
      <section className="h-72 bg-gradient-to-tl from-primary-dark to-primary-light relative after:content-[''] after:block after:bg-mask after:bg-['100%'] after:bg-bottom after:bg-no-repeat after:absolute after:inset-x-0 after:-bottom-1 after:w-full after:h-36 after:z-10">
        <div className="absolute w-full text-center inset-x-0 top-1/2 -translate-y-1/2 z-10 lg:p-20">
        <h1 className="animate__animated animate__fadeInDown animate__slower font-heading text-center text-3xl lg:text-5xl text-white font-bold mb-4">HÃY ĐỒNG HÀNH CÙNG KIM!</h1>
        </div>
      </section>

      <section className="container mt-8 mx-auto pt-14 px-4 lg:px-48 text-gray-700">
        <div className="text-base leading-loose space-y-6 mb-4">
          <p className="text-xl font-light text-center">Tham gia vào chiến dịch tranh cử và tìm hiểu cách quý vị có thể giúp chọn người lãnh đạo mới cho Địa hạt CA-45.</p>

          <p>Có nhiều cách để hỗ trợ chiến dịch tranh cử này, cả trực tiếp và từ xa. Cho dù quý vị có thể giúp gõ cửa nhà cử tri, treo biển quảng cáo trước nhà, hay gửi tin nhắn, mỗi người đều có thể đóng góp vào cuộc tranh cử này.</p>

          <p>Ai cũng có thể trở thành thiện nguyện viên, bất kể kinh nghiệm chính trị, tuổi tác hay khả năng.</p>
        </div>
        <Form />
      </section>
    </Layout>
  )
}

export default HowToHelp

export const Head = ({location}) => <SEO title="HÃY ĐỒNG HÀNH CÙNG KIM!" pathname={location.pathname} />
