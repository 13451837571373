import * as React from "react"
import ReCAPTCHA from "react-google-recaptcha";
import InputMask from "react-input-mask";
const axios = require('axios');

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    document.getElementById("submit_button").style.display = "none";
    var spinner = document.getElementById("spinner_container");
    spinner.classList.remove("hidden");
    spinner.setAttribute("aria-hidden", "false");

    var errorMessage = document.getElementById("error_message_container");
    errorMessage.classList.add("hidden");
    errorMessage.setAttribute("aria-hidden", "true");

    const formData = new FormData(event.target);
    formData.append('action','volunteer-submission');

    axios.post(
      process.env.GATSBY_API_ENDPOINT,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      },
    )
    .then( response => {
      // console.log(response);
      var form = document.getElementById("submission_form");
      form.classList.add('hidden')

      var message = document.getElementById("success_message");
      message.classList.remove("hidden");
      message.setAttribute("aria-hidden", "false");

    })
    .catch(function (error) {
      // console.log(error);
      // var errorMessage = document.getElementById("error_message_container");
      errorMessage.classList.remove("hidden");
      errorMessage.setAttribute("aria-hidden", "false");
      errorMessage.innerHTML = 'ERROR: <code>' + error.response.data.message + '</code>';
    
      document.getElementById("submit_button").style.display = "inline-block";
      var spinner = document.getElementById("spinner_container");
      spinner.classList.add("hidden");
      spinner.setAttribute("aria-hidden", "true");

    });

  }

  render() {
    return (
      <div>
        <div className="p-4 w-full bg-green-200 hidden" aria-hidden="true" id="success_message">
        Cảm ơn bạn, bài nộp của bạn đã được gửi thành công.
        </div>
        <form method="POST" onSubmit={this.handleSubmit} id="submission_form">
          <div className="lg:flex items-start justify-center">
            <div className="lg:w-1/2 p-4">
              <label htmlFor="fname" className="text-base mb-2 inline-block">Tên<span className="text-red-400 align-super text-sm">*</span></label>
              <input type="text" id="fname" name="fname" className="w-full p-4 border border-gray-500 rounded-md drop-shadow-sm focus:border-primary" required />
            </div>
            <div className="lg:w-1/2 p-4">
              <label htmlFor="lname" className="text-base mb-2 inline-block">Họ<span className="text-red-400 align-super">*</span></label>
              <input type="text" id="lname" name="lname" className="w-full p-4 border border-gray-500 rounded-md drop-shadow-sm focus:border-primary" required />
            </div>
          </div>
  
          <div className="p-4 w-full">
            <label htmlFor="email" className="text-base mb-2 inline-block">Địa chỉ Email<span className="text-red-400 align-super">*</span></label>
            <input type="email" id="email" name="email" className="w-full p-4 border border-gray-500 rounded-md drop-shadow-sm focus:border-primary" required />
          </div>
  
          <div className="lg:flex items-start justify-center">
            <div className="lg:w-1/2 p-4">
              
              <label htmlFor="phone" className="text-base mb-2 inline-block">Số điện thoại<span className="text-red-400 align-super text-sm">*</span></label>
              <InputMask mask="999-999-9999" id="phone" name="phone" className="w-full p-4 border border-gray-500 rounded-md drop-shadow-sm focus:border-primary peer" required />
              <span className="block mt-1 sm text-gray-400">Use format: 714-123-4567</span>
            </div>
            <div className="lg:w-1/2 p-4">
              <label htmlFor="zip" className="text-base mb-2 inline-block">Mã zip<span className="text-red-400 align-super">*</span></label>
              <InputMask mask="99999" id="zip" name="zip" className="w-full p-4 border border-gray-500 rounded-md drop-shadow-sm focus:border-primary peer" required />
              {/* <input type="text" id="zip" name="zip" minLength="5" maxlength="5" pattern="(\d{5}" className="w-full p-4 border border-gray-500 rounded-md drop-shadow-sm focus:border-primary" required onChange={this.handleChange.bind(this, "zip")} /> */}
              <span className="block mt-1 sm text-gray-400">Enter your 5 digit zipcode.</span>
            </div>
          </div>
  
          <div className="p-4 w-full space-y-4">
            <p className="text-base ml-2 ">Tôi muốn giúp làm công việc: (đánh dấu tất cả ô phù hợp)</p>
            <div className="pl-2">
              <input type="checkbox" id="help0" name="help[]" value="Knock on doors" className="" />
              <label htmlFor="help0" className="text-base ml-2 inline-block">Gõ cửa cử tri</label>
            </div>
  
            <div className="pl-2">
              <input type="checkbox" id="help1" name="help[]" value="Make phone calls" />
              <label htmlFor="help1" className="text-base ml-2 inline-block">Gọi điện thoại</label>
            </div>
  
            <div className="pl-2">
              <input type="checkbox" id="help2" name="help[]" value="Send text messages" />
              <label htmlFor="help2" className="text-base ml-2 inline-block">Gửi tin nhắn </label>
            </div>
  
            <div className="pl-2">
              <input type="checkbox" id="help3" name="help[]" value="Host a meet and greet or event" />
              <label htmlFor="help3" className="text-base ml-2 inline-block">Tổ chức một buổi gặp gỡ hoặc sinh hoạt</label>
            </div>
  
            <div className="pl-2">
              <input type="checkbox" id="help4" name="help[]" value="Write Postcards" />
              <label htmlFor="help4" className="text-base ml-2 inline-block">Viết bưu thiếp</label>
            </div>
  
            <div className="pl-2">
              <input type="checkbox" id="help5" name="help[]" value="Publicly endorse" />
              <label htmlFor="help5" className="text-base ml-2 inline-block">Công khai bày tỏ ủng hộ</label>
            </div>
          </div>
  
          <div className="p-4 w-full space-y-4">
            <ReCAPTCHA
              style={{overflow: 'hidden'}}
              sitekey={process.env.GATSBY_ReCAPTCHA_SITE_KEY}
            />
            <input id="submit_button" type="submit" value="Nộp" className="p-4 font-bold border border-secondary-dark bg-secondary text-black transistion-all duration-150 ease-linear hover:bg-secondary-dark hover:outline hover:outline-4 outline-secondary hover:cursor-pointer disabled:bg-gray-300 disabled:text-gray-400 disabled:border-gray-400 disabled:hover:outline-none disabled:cursor-default"/>
            
            <div aria-hidden="true" id="spinner_container" className="hidden">
              <div className="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="30.849" height="30.729" viewBox="0 0 30.849 30.729" className="animate-spin">
                  <path id="Icon_metro-spinner2" data-name="Icon metro-spinner2" d="M8.355,17.352a9.735,9.735,0,0,1,.061-1.085L2.843,14.456A15.438,15.438,0,0,0,7.441,28.6l3.443-4.739A9.6,9.6,0,0,1,8.355,17.352Zm19.28,0a9.6,9.6,0,0,1-2.529,6.508L28.549,28.6a15.439,15.439,0,0,0,4.6-14.142l-5.573,1.811a9.75,9.75,0,0,1,.061,1.085ZM19.923,7.905a9.653,9.653,0,0,1,6.46,4.694l5.573-1.811A15.437,15.437,0,0,0,19.923,2.048V7.905ZM9.607,12.6a9.653,9.653,0,0,1,6.46-4.694V2.048A15.437,15.437,0,0,0,4.034,10.789L9.607,12.6ZM21.988,26.129a9.663,9.663,0,0,1-7.987,0l-3.443,4.739a15.44,15.44,0,0,0,14.873,0Z" transform="translate(-2.571 -2.048)" fill="#fcca0b"/>
                </svg>
                <p className="pl-5">Xử lý...</p>
              </div>
            </div>

          </div>
  
        </form>
        <div className="p-4 w-full bg-red-200 hidden" aria-hidden="true" id="error_message_container">
        Xin lỗi, có lỗi xảy ra. Vui lòng thử lại.
        </div>
      </div>
    );
  }
}